html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  /* background: linear-gradient(180deg, #bfddff 0%, #7ab7ff 100%); */
  background-color: #bfddff;
}

* {
  box-sizing: border-box;
}
